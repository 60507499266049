var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"download-list"},_vm._l((_vm.content),function(item){return _c('li',{key:item.id},[(item.status !== 'DISABLED')?_c(_vm.tag(item),{tag:"component",staticClass:"download-list__item",class:{ 'is-disabled': item.status === 'PENDING', 'has-error': _vm.hasError(item.id) },attrs:{"href":item.status === 'OK' ? item.url : undefined,"download":Boolean(item.url) && item.status === 'OK' ? item.title : undefined}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"download-list__type",attrs:{"size":"0 0 28 27","symbol":("icons-" + (item.fileType))}}),_c('span',{staticClass:"p"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"download-list__item__link"},[(item.status === 'PENDING')?[_vm._v(" "+_vm._s(_vm.$t('singleLoan.generating'))+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"icons-sandglass"}})]:(item.status !== 'OK')?[(item.id === 'RBA')?_c('g-btn',{staticClass:"list__item__cta btn--outline",attrs:{"btn":{
              icon: {
                symbol: 'icons-clock',
                size: '0 0 20 21',
              },
            },"tag":"button","disabled":_vm.isSubmitted(item.id)},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onDocRequest(item)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('singleLoan.docRequest.request'))+" ")]),_c('b',[_vm._v(" "+_vm._s(_vm.$t('singleLoan.docRequest.error'))+" ")])]):(item.id === 'DEATH' || item.id === 'PROOF')?_c('g-btn',{staticClass:"list__item__cta btn--outline",attrs:{"btn":{
              icon: {
                symbol: 'icons-upload',
                size: '0 0 22 21',
              },
            },"tag":"button","disabled":_vm.isSubmitted(item.id)},nativeOn:{"click":function($event){$event.preventDefault();return _vm.$emit(item.id === 'DEATH' ? 'death' : 'proof')}}},[_vm._v(" "+_vm._s(_vm.$t(item.id === 'DEATH' ? 'singleLoan.docRequest.death' : 'singleLoan.docRequest.prouve'))+" ")]):(item.status === 'ASK')?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onDocRequest(item)}}},[_vm._v(" "+_vm._s(_vm.$t('singleLoan.generate'))+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 20 21","symbol":"icons-clock"}})]):_vm._e()]:(item.url && item.status === 'OK')?[_vm._v(" "+_vm._s(_vm.$t('singleLoan.download'))+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 20 21","symbol":"icons-download"}})]:_vm._e()],2)]):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }