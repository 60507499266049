



















































































































































import { defineComponent, PropType, ref } from '@vue/composition-api'

import PaymentProof from '@/components/popups/PaymentProof.vue'
import Alert from '@/components/ui/Alert.vue'
import { Loan } from '@/views/SingleLoan.vue'
import DownloadList from '@/components/DownloadList.vue'

export default defineComponent({
  name: 'payments',
  components: { PaymentProof, Alert, DownloadList },
  props: {
    loan: {
      type: Object as PropType<Loan>,
      required: true,
    },
  },

  setup(props) {
    const paymentProofVisible = ref(false)
    const previousPayements = props.loan.documents?.find(d => d.id === 'SIT')

    return { previousPayements, paymentProofVisible }
  },
})
